interface StatisticsFooterProps {
  source: string;
  lastUpdate: string;
  reliability: number;
}

export default function StatisticsFooter({ source, lastUpdate, reliability }: StatisticsFooterProps) {
  return (
    <div className="mt-6 text-sm text-gray-500">
      <p>Source: {source}</p>
      <p>Dernière mise à jour: {new Date(lastUpdate).toLocaleDateString()}</p>
      {reliability > 0 && (
        <p className="mt-2 text-xs">
          Fiabilité des données: {reliability}%
          <span className="block mt-1">
            Ces statistiques sont basées sur des données réelles de cambriolages.
          </span>
        </p>
      )}
    </div>
  );
}