import { Link } from 'react-router-dom';

const steps = [
  {
    number: 1,
    title: "Remplissez le formulaire",
    description: "Décrivez votre logement et vos équipements actuels en quelques clics."
  },
  {
    number: 2,
    title: "Obtenez votre diagnostic",
    description: "Recevez une analyse détaillée des points forts et des vulnérabilités."
  },
  {
    number: 3,
    title: "Sécurisez votre logement",
    description: "Suivez nos recommandations personnalisées pour améliorer votre sécurité."
  }
];

export default function HowItWorks() {
  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Comment ça marche ?
          </h2>
        </div>
        <div className="mt-10">
          <div className="relative">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {steps.map((step) => (
                <div key={step.number} className="text-center">
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white mx-auto">
                    {step.number}
                  </div>
                  <h3 className="mt-4 text-lg font-medium text-gray-900">{step.title}</h3>
                  <p className="mt-2 text-base text-gray-500">{step.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-10 text-center">
          <Link
            to="/diagnostic"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:text-lg"
          >
            Lancer mon diagnostic
          </Link>
        </div>
      </div>
    </div>
  );
}