interface StepIndicatorProps {
  currentStep: number;
}

const steps = [
  { number: 1, title: 'Type de logement' },
  { number: 2, title: 'Localisation' },
  { number: 3, title: 'Équipements actuels' }
];

export default function StepIndicator({ currentStep }: StepIndicatorProps) {
  return (
    <nav aria-label="Progress">
      <ol className="flex items-center justify-between w-full">
        {steps.map((step) => (
          <li key={step.number} className="relative flex items-center">
            <div className={`flex items-center ${currentStep >= step.number ? 'text-blue-600' : 'text-gray-500'}`}>
              <span className={`w-8 h-8 flex items-center justify-center rounded-full border-2 
                ${currentStep >= step.number ? 'border-blue-600 bg-blue-600 text-white' : 'border-gray-300'}`}>
                {step.number}
              </span>
              <span className="ml-2 text-sm font-medium">{step.title}</span>
            </div>
            {step.number < steps.length && (
              <div className={`flex-1 w-20 h-0.5 mx-4 ${currentStep > step.number ? 'bg-blue-600' : 'bg-gray-300'}`} />
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}