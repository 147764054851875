import { Link, useLocation } from 'react-router-dom';
import { ShieldCheckIcon } from '@heroicons/react/24/solid';
import LoginButton from './auth/LoginButton';

function Navbar() {
  const location = useLocation();
  const hideStartButton = location.pathname === '/diagnostic' || location.pathname === '/results';

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex items-center space-x-2 hover:opacity-90 transition-opacity">
              <ShieldCheckIcon className="h-8 w-8 text-primary" />
              <span className="text-xl font-bold text-gray-900">PROTECTMYHOME</span>
            </Link>
            <div className="hidden md:ml-6 md:flex md:space-x-8">
              <Link
                to="/blog"
                className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 hover:text-primary"
              >
                Blog
              </Link>
            </div>
          </div>
          
          <div className="flex items-center space-x-4">
            {!hideStartButton && (
              <Link
                to="/diagnostic"
                className="hidden md:inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark"
              >
                Lancer le diagnostic
              </Link>
            )}
            <LoginButton />
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;