import { DiagnosticFormData } from '../../types/diagnostic';
import { getVulnerableAreas } from '../../utils/securityCalculator';
import { motion } from 'framer-motion';
import { ShieldExclamationIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';

interface SecurityMapProps {
  formData: DiagnosticFormData;
}

const riskColors = {
  high: {
    bg: 'bg-red-100',
    border: 'border-red-500',
    text: 'text-red-700',
    icon: 'text-red-500'
  },
  medium: {
    bg: 'bg-yellow-100',
    border: 'border-yellow-500',
    text: 'text-yellow-700',
    icon: 'text-yellow-500'
  },
  low: {
    bg: 'bg-green-100',
    border: 'border-green-500',
    text: 'text-green-700',
    icon: 'text-green-500'
  }
};

export default function SecurityMap({ formData }: SecurityMapProps) {
  const vulnerableAreas = getVulnerableAreas(formData);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-xl font-semibold text-gray-900 mb-6">Diagnostic des vulnérabilités</h2>
      
      {vulnerableAreas.length > 0 ? (
        <div className="grid grid-cols-1 gap-4">
          {vulnerableAreas.map((area, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className={`relative ${riskColors[area.risk].bg} border-l-4 ${riskColors[area.risk].border} rounded-lg p-4`}
            >
              <div className="flex items-center">
                {area.risk === 'high' ? (
                  <ShieldExclamationIcon className={`h-8 w-8 ${riskColors[area.risk].icon} mr-3`} />
                ) : (
                  <ShieldCheckIcon className={`h-8 w-8 ${riskColors[area.risk].icon} mr-3`} />
                )}
                
                <div className="flex-1">
                  <h3 className={`font-semibold ${riskColors[area.risk].text}`}>
                    {area.label}
                  </h3>
                  <div className="mt-2">
                    <div className="flex items-center space-x-2">
                      <div className="flex-1 h-3 bg-gray-200 rounded-full overflow-hidden">
                        <div 
                          className={`h-full ${
                            area.risk === 'high' ? 'bg-red-500' :
                            area.risk === 'medium' ? 'bg-yellow-500' :
                            'bg-green-500'
                          }`}
                          style={{ 
                            width: `${
                              area.risk === 'high' ? '100%' :
                              area.risk === 'medium' ? '66%' :
                              '33%'
                            }`
                          }}
                        />
                      </div>
                      <span className={`text-sm font-medium ${riskColors[area.risk].text}`}>
                        {area.risk === 'high' ? 'Risque élevé' :
                         area.risk === 'medium' ? 'Risque modéré' :
                         'Risque faible'}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-3 text-sm text-gray-600">
                {area.description}
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-green-50 border-l-4 border-green-500 rounded-lg p-4"
        >
          <div className="flex items-center">
            <ShieldCheckIcon className="h-8 w-8 text-green-500 mr-3" />
            <div>
              <h3 className="font-semibold text-green-700">Aucune vulnérabilité majeure détectée</h3>
              <p className="mt-1 text-sm text-green-600">
                Votre logement présente un bon niveau de sécurité. Continuez à maintenir vos équipements et à suivre les bonnes pratiques.
              </p>
            </div>
          </div>
        </motion.div>
      )}

      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Légende</h3>
        <div className="grid grid-cols-3 gap-2">
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 bg-red-500 rounded-full"></div>
            <span className="text-xs text-gray-600">Risque élevé</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
            <span className="text-xs text-gray-600">Risque modéré</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-3 h-3 bg-green-500 rounded-full"></div>
            <span className="text-xs text-gray-600">Risque faible</span>
          </div>
        </div>
      </div>
    </div>
  );
}