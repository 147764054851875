import { motion } from 'framer-motion';

interface BlogCategoriesProps {
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
}

const CATEGORIES = [
  { id: 'technologies', name: 'Technologies' },
  { id: 'conseils', name: 'Conseils' },
  { id: 'actualites', name: 'Actualités' },
  { id: 'guides', name: 'Guides pratiques' }
];

export default function BlogCategories({ selectedCategory, onSelectCategory }: BlogCategoriesProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Catégories</h2>
      <div className="space-y-2">
        <motion.button
          whileHover={{ scale: 1.02 }}
          onClick={() => onSelectCategory(null)}
          className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
            !selectedCategory
              ? 'bg-blue-100 text-blue-800'
              : 'hover:bg-gray-100'
          }`}
        >
          Tous les articles
        </motion.button>
        {CATEGORIES.map((category) => (
          <motion.button
            key={category.id}
            whileHover={{ scale: 1.02 }}
            onClick={() => onSelectCategory(category.id)}
            className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
              selectedCategory === category.id
                ? 'bg-blue-100 text-blue-800'
                : 'hover:bg-gray-100'
            }`}
          >
            {category.name}
          </motion.button>
        ))}
      </div>
    </div>
  );
}