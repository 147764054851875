import { motion } from 'framer-motion';

interface NavigationProps {
  currentStep: number;
  onPrevious: () => void;
  onNext: () => void;
  isValid: boolean;
}

export default function Navigation({ currentStep, onPrevious, onNext, isValid }: NavigationProps) {
  return (
    <div className="mt-8 flex justify-between">
      {currentStep > 1 ? (
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          type="button"
          onClick={onPrevious}
          className="btn-secondary"
        >
          Précédent
        </motion.button>
      ) : (
        <div />
      )}
      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        type="button"
        onClick={onNext}
        disabled={!isValid}
        className={`btn-primary ${!isValid ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {currentStep === 3 ? 'Voir les résultats' : 'Suivant'}
      </motion.button>
    </div>
  );
}