import { BookOpenIcon } from '@heroicons/react/24/outline';

export default function SecurityGuide() {
  return (
    <div className="mt-8 bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-xl font-semibold text-gray-900">Guide des bonnes pratiques</h2>
          <p className="mt-2 text-gray-600">
            Consultez notre guide complet des bonnes pratiques pour maintenir votre logement en sécurité.
          </p>
        </div>
        <button
          onClick={() => window.open('/guide-securite.pdf', '_blank')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          <BookOpenIcon className="h-5 w-5 mr-2" />
          Consulter le guide
        </button>
      </div>
    </div>
  );
}