import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { UserIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

export default function LoginButton() {
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      toast.success('Déconnexion réussie');
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
    }
  };

  return currentUser ? (
    <div className="flex items-center space-x-4">
      <Link
        to="/dashboard"
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark"
      >
        <UserIcon className="h-5 w-5 mr-2" />
        Mon compte
      </Link>
      <button
        onClick={handleLogout}
        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
      >
        <ArrowLeftOnRectangleIcon className="h-5 w-5 mr-2" />
        Déconnexion
      </button>
    </div>
  ) : (
    <Link
      to="/login"
      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark"
    >
      <UserIcon className="h-5 w-5 mr-2" />
      Se connecter
    </Link>
  );
}