import { DiagnosticFormData } from '../../../types/diagnostic';
import { motion } from 'framer-motion';
import securityWeights from '../../../config/securityWeights.json';

interface SecurityEquipmentProps {
  formData: DiagnosticFormData;
  updateFormData: (data: Partial<DiagnosticFormData>) => void;
}

type SecurityEquipment = {
  id: keyof typeof securityWeights.equipment;
  label: string;
  description: string;
  categories: string[];
};

const SECURITY_ITEMS: SecurityEquipment[] = Object.entries(securityWeights.equipment).map(([id, data]) => ({
  id: id as keyof typeof securityWeights.equipment,
  label: data.label,
  description: data.description,
  categories: data.categories
}));

const CATEGORIES: Record<string, { label: string; color: string }> = {
  protection: { label: 'Protection', color: 'bg-red-100 text-red-800' },
  surveillance: { label: 'Surveillance', color: 'bg-blue-100 text-blue-800' },
  deterrence: { label: 'Dissuasion', color: 'bg-green-100 text-green-800' }
};

export default function SecurityEquipment({ formData, updateFormData }: SecurityEquipmentProps) {
  const handleEquipmentChange = (id: string, checked: boolean) => {
    const newEquipment = checked
      ? [...formData.equipment, id]
      : formData.equipment.filter(item => item !== id);
    
    updateFormData({ equipment: newEquipment });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Équipements de sécurité actuels</h2>
      
      <div className="mb-6 flex flex-wrap gap-2">
        {Object.entries(CATEGORIES).map(([key, { label, color }]) => (
          <span key={key} className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${color}`}>
            {label}
          </span>
        ))}
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {SECURITY_ITEMS.map(({ id, label, description, categories }) => (
          <motion.div
            key={id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="relative flex flex-col p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-start space-x-3">
              <input
                type="checkbox"
                id={id}
                checked={formData.equipment.includes(id)}
                onChange={(e) => handleEquipmentChange(id, e.target.checked)}
                className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded cursor-pointer mt-1"
              />
              <div className="flex-1">
                <label htmlFor={id} className="text-base font-medium text-gray-900 cursor-pointer">
                  {label}
                </label>
                <p className="text-sm text-gray-500 mt-1">{description}</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {categories.map(category => (
                    <span
                      key={category}
                      className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${CATEGORIES[category].color}`}
                    >
                      {CATEGORIES[category].label}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>

      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <p className="text-sm text-gray-600">
          Sélectionnez tous les équipements de sécurité actuellement installés dans votre logement.
          Cette information nous permettra de calculer votre score de sécurité et de vous proposer des recommandations personnalisées.
        </p>
      </div>
    </div>
  );
}