import { useState, useEffect, useCallback } from 'react';
import { DiagnosticFormData } from '../../../types/diagnostic';
import { getCitiesFromPostalCode, CommuneData } from '../../../services/addressService';
import { motion, AnimatePresence } from 'framer-motion';

interface LocationProps {
  formData: DiagnosticFormData;
  updateFormData: (data: Partial<DiagnosticFormData>) => void;
}

export default function Location({ formData, updateFormData }: LocationProps) {
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lastSearchedPostalCode, setLastSearchedPostalCode] = useState('');
  const [availableCities, setAvailableCities] = useState<CommuneData[]>([]);
  const [showCityDropdown, setShowCityDropdown] = useState(false);

  const lookupCities = useCallback(async (postalCode: string) => {
    if (postalCode === lastSearchedPostalCode || postalCode.length !== 5) return;
    
    setIsLoadingCity(true);
    setError(null);
    setAvailableCities([]);
    
    try {
      const cities = await getCitiesFromPostalCode(postalCode);
      if (cities.length > 0) {
        setAvailableCities(cities);
        setLastSearchedPostalCode(postalCode);
        
        if (cities.length === 1) {
          // Si une seule ville, on la sélectionne automatiquement
          updateFormData({ 
            city: cities[0].nom,
            cityData: {
              population: cities[0].population,
              logements: cities[0].logements
            }
          });
          setShowCityDropdown(false);
        } else {
          // Si plusieurs villes, on affiche la liste déroulante
          setShowCityDropdown(true);
          updateFormData({ 
            city: '',
            cityData: undefined
          });
        }
      } else {
        setError('Code postal non trouvé');
      }
    } catch (err) {
      setError('Erreur lors de la recherche des communes');
    } finally {
      setIsLoadingCity(false);
    }
  }, [lastSearchedPostalCode, updateFormData]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (formData.postalCode.length === 5) {
        lookupCities(formData.postalCode);
      } else if (formData.city && !formData.postalCode) {
        updateFormData({ 
          city: '',
          cityData: undefined
        });
        setAvailableCities([]);
        setShowCityDropdown(false);
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [formData.postalCode, lookupCities, formData.city, updateFormData]);

  const handlePostalCodeChange = (value: string) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    if (numericValue.length <= 5) {
      if (numericValue !== formData.postalCode) {
        updateFormData({ 
          postalCode: numericValue,
          city: '',
          cityData: undefined
        });
        if (numericValue.length < 5) {
          setError(null);
          setLastSearchedPostalCode('');
          setAvailableCities([]);
          setShowCityDropdown(false);
        }
      }
    }
  };

  const handleCitySelect = (city: CommuneData) => {
    updateFormData({ 
      city: city.nom,
      cityData: {
        population: city.population,
        logements: city.logements
      }
    });
    setShowCityDropdown(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="space-y-6"
    >
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Localisation</h2>
      
      <div className="space-y-6">
        <div>
          <label htmlFor="address" className="block text-sm font-medium text-gray-700">
            Adresse
          </label>
          <input
            type="text"
            id="address"
            value={formData.address}
            onChange={(e) => updateFormData({ address: e.target.value })}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                     focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            placeholder="123 rue de la Sécurité"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
              Code postal
            </label>
            <input
              type="text"
              id="postalCode"
              value={formData.postalCode}
              onChange={(e) => handlePostalCodeChange(e.target.value)}
              className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                       focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
                       ${error ? 'border-red-300' : ''}`}
              placeholder="75001"
              maxLength={5}
            />
            {error && (
              <p className="mt-1 text-sm text-red-600">{error}</p>
            )}
          </div>

          <div>
            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
              Ville
            </label>
            <div className="relative">
              <input
                type="text"
                id="city"
                value={formData.city}
                className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                         focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50
                         ${isLoadingCity ? 'bg-gray-50' : ''}`}
                placeholder="Paris"
                readOnly
              />
              {isLoadingCity && (
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                  <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary"></div>
                </div>
              )}

              <AnimatePresence>
                {showCityDropdown && availableCities.length > 1 && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200"
                  >
                    <ul className="max-h-60 overflow-auto py-1">
                      {availableCities.map((city) => (
                        <li
                          key={city.code}
                          onClick={() => handleCitySelect(city)}
                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-sm text-gray-700"
                        >
                          {city.nom}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {availableCities.length > 1 && !formData.city && (
              <p className="mt-1 text-sm text-gray-500">
                Plusieurs communes trouvées, veuillez en sélectionner une
              </p>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
}