import { getAssetPath } from '../utils/assetPath';

export const blogPosts = [
  {
    id: 1,
    title: "Les systèmes d'alarme connectés",
    slug: "systemes-alarme-connectes",
    excerpt: "Découvrez comment les nouvelles technologies révolutionnent la sécurité domestique.",
    content: `
      <h2>La révolution des alarmes connectées</h2>
      <p>Les systèmes d'alarme modernes offrent bien plus que la simple détection d'intrusion...</p>
    `,
    image: getAssetPath("./images/blog/smart-alarm.jpg"),
    category: "Technologies",
    date: "2024-01-15",
    readTime: 8,
    author: "Guillaume Martin"
  },
  {
    id: 2,
    title: "Sécuriser ses fenêtres efficacement",
    slug: "securiser-fenetres",
    excerpt: "Guide complet pour protéger les points d'accès les plus vulnérables de votre logement.",
    content: `
      <h2>Les points faibles des fenêtres</h2>
      <p>Les fenêtres représentent souvent le point d'entrée privilégié des cambrioleurs...</p>
    `,
    image: getAssetPath("./images/blog/window-security.jpg"),
    category: "Conseils",
    date: "2024-01-10",
    readTime: 6,
    author: "Sophie Dubois"
  },
  {
    id: 3,
    title: "Partir en vacances l'esprit tranquille",
    slug: "securite-vacances",
    excerpt: "Checklist complète des mesures à prendre avant un départ en vacances.",
    content: `
      <h2>Préparer son absence</h2>
      <p>Un logement inoccupé attire particulièrement l'attention des cambrioleurs...</p>
    `,
    image: getAssetPath("./images/blog/vacation-security.jpg"),
    category: "Guides pratiques",
    date: "2024-01-05",
    readTime: 7,
    author: "Marc Leroy"
  }
];