import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BlogPost } from '../../types/blog';
import { formatDate } from '../../utils/dateFormatter';
import { getAssetPath } from '../../utils/assetPath';

interface BlogPostCardProps {
  post: BlogPost;
  index: number;
}

export default function BlogPostCard({ post, index }: BlogPostCardProps) {
  return (
    <motion.article
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      className="bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <Link to={`/blog/${post.slug}`}>
        <img
          src={getAssetPath(post.image)}
          alt={post.title}
          className="w-full h-48 object-cover transition-transform duration-300 hover:scale-105"
        />
      </Link>
      {/* Reste du composant inchangé */}
    </motion.article>
  );
}