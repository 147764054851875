import { motion } from 'framer-motion';

export default function LegalNotice() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto px-4 py-12"
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Mentions Légales</h1>

      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Informations légales</h2>
          <p className="text-gray-600">
            Le site PROTECTMYHOME est édité par :<br />
            [Nom de la société]<br />
            [Adresse du siège social]<br />
            SIRET : [Numéro SIRET]<br />
            Capital social : [Montant]<br />
            Téléphone : [Numéro]<br />
            Email : contact@protectmyhome.fr
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Hébergement</h2>
          <p className="text-gray-600">
            Ce site est hébergé par StackBlitz<br />
            StackBlitz, Inc.<br />
            355 Bryant Street, Unit 403<br />
            San Francisco, CA 94107<br />
            États-Unis
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Propriété intellectuelle</h2>
          <p className="text-gray-600">
            L'ensemble du contenu de ce site (structure, textes, images, logos, base de données...) 
            est protégé par le droit d'auteur. Toute reproduction, représentation, modification, 
            publication, adaptation ou exploitation de tout ou partie des éléments du site, quel 
            que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Responsabilité</h2>
          <p className="text-gray-600">
            Les informations fournies sur ce site le sont à titre indicatif. PROTECTMYHOME ne saurait 
            garantir l'exactitude, la complétude, l'actualité des informations diffusées sur le site. 
            En conséquence, l'utilisateur reconnaît utiliser ces informations sous sa responsabilité 
            exclusive.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Liens hypertextes</h2>
          <p className="text-gray-600">
            Le site peut contenir des liens vers d'autres sites internet ou d'autres ressources 
            disponibles sur Internet. PROTECTMYHOME n'a pas la possibilité de vérifier le contenu des 
            sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Modification des mentions légales</h2>
          <p className="text-gray-600">
            PROTECTMYHOME se réserve le droit de modifier la présente notice à tout moment. L'utilisateur 
            s'engage donc à la consulter régulièrement.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Droit applicable</h2>
          <p className="text-gray-600">
            Les présentes mentions légales sont régies par le droit français. En cas de litige, 
            les tribunaux français seront seuls compétents.
          </p>
        </section>
      </div>
    </motion.div>
  );
}