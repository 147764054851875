import Hero from '../components/home/Hero';
import Features from '../components/home/Features';
import HowItWorks from '../components/home/HowItWorks';
import CallToAction from '../components/home/CallToAction';
import SecurityGuideSection from '../components/home/SecurityGuideSection';
import BlogSection from '../components/home/BlogSection';

export default function Home() {
  return (
    <div className="max-w-7xl mx-auto">
      <Hero />
      <Features />
      <HowItWorks />
      <SecurityGuideSection />
      <BlogSection />
      <CallToAction />
    </div>
  );
}