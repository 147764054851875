import axios from 'axios';

export interface CommuneData {
  nom: string;
  code: string;
  codeDepartement: string;
  codeRegion: string;
  codesPostaux: string[];
  population: number;
  logements: number;
};

export async function getCitiesFromPostalCode(postalCode: string): Promise<CommuneData[]> {
  try {
    if (postalCode.length !== 5) return [];
    
    // Première requête pour obtenir les communes correspondant au code postal
    const geoResponse = await axios.get<CommuneData[]>(
      `https://geo.api.gouv.fr/communes?codePostal=${postalCode}&fields=nom,code,codeDepartement,codeRegion,codesPostaux`,
      {
        timeout: 5000,
        headers: {
          'Accept': 'application/json'
        }
      }
    );

    if (!geoResponse.data || geoResponse.data.length === 0) {
      return [];
    }

    // Pour chaque commune, récupérer les données INSEE
    const citiesWithData = await Promise.all(
      geoResponse.data.map(async (commune) => {
        try {
          const inseeResponse = await axios.get(
            `https://api.insee.fr/recensement/2019/communes/${commune.code}`,
            {
              headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${import.meta.env.VITE_INSEE_API_KEY}`
              }
            }
          );

          const inseeData = inseeResponse.data;
          return {
            ...commune,
            population: inseeData.population.total,
            logements: inseeData.logements.total
          };
        } catch (error) {
          console.warn(`Impossible de récupérer les données INSEE pour ${commune.nom}:`, error);
          // Utiliser des estimations basées sur les moyennes nationales si les données INSEE ne sont pas disponibles
          const estimatedHouseholdSize = 2.2; // Taille moyenne des ménages en France
          const estimatedPopulation = Math.round(commune.population || 0);
          const estimatedHousing = Math.round(estimatedPopulation / estimatedHouseholdSize);
          
          return {
            ...commune,
            population: estimatedPopulation,
            logements: estimatedHousing
          };
        }
      })
    );

    return citiesWithData;
  } catch (error) {
    console.error('Erreur lors de la récupération des communes:', error);
    throw new Error('Impossible de récupérer les communes');
  }
}