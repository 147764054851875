import { DiagnosticFormData } from '../types/diagnostic';
import securityWeights from '../config/securityWeights.json';

interface SecurityDetail {
  label: string;
  score: number;
  maxScore: number;
  percentage: number;
}

interface SecurityScore {
  score: number;
  details: SecurityDetail[];
  categoryScores: Record<string, number>;
}

interface Recommendation {
  title: string;
  priority: 'high' | 'medium' | 'low';
  items: string[];
}

interface VulnerableArea {
  label: string;
  risk: 'high' | 'medium' | 'low';
  description: string;
}

export function calculateSecurityScore(formData: DiagnosticFormData): SecurityScore {
  // Score de base selon le type de logement
  let baseScore = securityWeights.baseScores[formData.housingType];
  
  // Impact du nombre d'étages
  const floorPenalty = Math.min(
    formData.floors * securityWeights.floorImpact.perFloor,
    securityWeights.floorImpact.maxPenalty
  );
  baseScore += floorPenalty;

  // Modificateurs liés aux dépendances
  if (formData.hasBasement) baseScore += securityWeights.locationModifiers.hasBasement;
  if (formData.hasGarage) baseScore += securityWeights.locationModifiers.hasGarage;
  if (formData.hasAccessibleGarden) baseScore += securityWeights.locationModifiers.hasAccessibleGarden;

  // Modificateurs environnementaux
  if (formData.streetLighting) {
    baseScore += securityWeights.locationModifiers.streetLighting[formData.streetLighting];
  }
  if (formData.streetVisibility) {
    baseScore += securityWeights.locationModifiers.streetVisibility[formData.streetVisibility];
  }
  if (formData.neighborhoodType) {
    baseScore += securityWeights.locationModifiers.neighborhoodType[formData.neighborhoodType];
  }

  // Calcul des scores par catégorie
  const categoryScores: Record<string, number> = {
    protection: 0,
    surveillance: 0,
    deterrence: 0
  };

  // Points bonus des équipements
  formData.equipment.forEach(equipmentId => {
    const equipment = securityWeights.equipment[equipmentId as keyof typeof securityWeights.equipment];
    if (equipment) {
      baseScore += equipment.score;
      equipment.categories.forEach(category => {
        categoryScores[category] += equipment.score * securityWeights.categoryWeights[category as keyof typeof securityWeights.categoryWeights];
      });
    }
  });

  // Normalisation des scores par catégorie (sur 100)
  Object.keys(categoryScores).forEach(category => {
    categoryScores[category] = Math.min(100, Math.round((categoryScores[category] / 20) * 100));
  });

  // Score final (limité entre 0 et 100)
  const finalScore = Math.max(0, Math.min(100, Math.round(baseScore)));

  return {
    score: finalScore,
    details: [
      {
        label: "Protection physique",
        score: categoryScores.protection,
        maxScore: 100,
        percentage: categoryScores.protection
      },
      {
        label: "Surveillance",
        score: categoryScores.surveillance,
        maxScore: 100,
        percentage: categoryScores.surveillance
      },
      {
        label: "Dissuasion",
        score: categoryScores.deterrence,
        maxScore: 100,
        percentage: categoryScores.deterrence
      }
    ],
    categoryScores
  };
}

export function getRecommendations(formData: DiagnosticFormData): Recommendation[] {
  const { categoryScores } = calculateSecurityScore(formData);
  const recommendations: Recommendation[] = [];

  // Recommandations pour la protection physique
  if (categoryScores.protection < 65) {
    recommendations.push({
      title: "Protection physique à renforcer",
      priority: categoryScores.protection < 35 ? "high" : "medium",
      items: [
        !formData.equipment.includes('door') ? "Installer une porte blindée" : "",
        !formData.equipment.includes('smartLock') ? "Équiper les portes de serrures connectées" : "",
        !formData.equipment.includes('windowLocks') ? "Installer des serrures de sécurité sur les fenêtres" : "",
        !formData.equipment.includes('bars') && formData.housingType === 'house' ? 
          "Équiper les fenêtres accessibles de barreaux ou de vitrage sécurisé" : "",
        !formData.equipment.includes('safe') ? "Installer un coffre-fort pour les objets de valeur" : "",
        formData.hasAccessibleGarden && !formData.equipment.includes('securityGate') ?
          "Sécuriser le périmètre avec un portail ou une clôture haute" : ""
      ].filter(Boolean)
    });
  }

  // Recommandations pour la surveillance
  if (categoryScores.surveillance < 65) {
    recommendations.push({
      title: "Système de surveillance à améliorer",
      priority: categoryScores.surveillance < 35 ? "high" : "medium",
      items: [
        !formData.equipment.includes('alarm') ? "Installer un système d'alarme" : "",
        !formData.equipment.includes('camera') ? "Mettre en place des caméras de surveillance" : "",
        !formData.equipment.includes('motionSensor') ? "Installer des détecteurs de mouvement" : "",
        !formData.equipment.includes('doorSensor') ? "Équiper les ouvertures de détecteurs" : "",
        !formData.equipment.includes('videoDoorbell') ? "Installer une sonnette vidéo connectée" : "",
        !formData.equipment.includes('intercom') ? "Installer un interphone ou visiophone" : ""
      ].filter(Boolean)
    });
  }

  // Recommandations pour la dissuasion
  if (categoryScores.deterrence < 65) {
    recommendations.push({
      title: "Mesures de dissuasion à mettre en place",
      priority: categoryScores.deterrence < 35 ? "high" : "medium",
      items: [
        !formData.equipment.includes('lighting') ? "Installer un éclairage extérieur avec détecteur de mouvement" : "",
        !formData.equipment.includes('fogSystem') && categoryScores.protection < 50 ? 
          "Envisager l'installation d'un système de brouillard anti-intrusion" : "",
        "Mettre en place une signalétique de sécurité visible",
        "Maintenir une apparence d'occupation pendant les absences"
      ].filter(Boolean)
    });
  }

  return recommendations;
}

export function getVulnerableAreas(formData: DiagnosticFormData): VulnerableArea[] {
  const vulnerableAreas: VulnerableArea[] = [];

  // Évaluation des points d'accès principaux
  if (!formData.equipment.includes('door')) {
    vulnerableAreas.push({
      label: "Points d'accès principaux",
      risk: 'high',
      description: "Les portes ne sont pas suffisamment sécurisées"
    });
  }

  // Évaluation de la surveillance
  if (!formData.equipment.includes('camera') && !formData.equipment.includes('alarm')) {
    vulnerableAreas.push({
      label: "Surveillance",
      risk: 'high',
      description: "Absence de système de surveillance"
    });
  }

  // Évaluation des ouvertures
  if (!formData.equipment.includes('bars') && formData.housingType === 'house') {
    vulnerableAreas.push({
      label: "Fenêtres et ouvertures",
      risk: 'medium',
      description: "Protection des fenêtres à renforcer"
    });
  }

  return vulnerableAreas;
}

export function getRiskLevel(score: number): 'high' | 'medium' | 'low' {
  if (score < securityWeights.vulnerabilityThresholds.high) return 'high';
  if (score < securityWeights.vulnerabilityThresholds.medium) return 'medium';
  return 'low';
}