import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { blogPosts } from '../data/blogPosts';
import RelatedPosts from '../components/blog/RelatedPosts';
import ShareButtons from '../components/blog/ShareButtons';
import { formatDate } from '../utils/dateFormatter';

export default function BlogPost() {
  const { slug } = useParams();
  const post = blogPosts.find(p => p.slug === slug);

  if (!post) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-2xl font-bold text-gray-900">Article non trouvé</h1>
        <p className="mt-4 text-gray-600">L'article que vous recherchez n'existe pas ou a été déplacé.</p>
      </div>
    );
  }

  const relatedPosts = blogPosts
    .filter(p => p.category === post.category && p.id !== post.id)
    .slice(0, 3);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12"
    >
      <article className="bg-white rounded-lg shadow-lg overflow-hidden">
        <img
          src={post.image}
          alt={post.title}
          className="w-full h-72 object-cover"
        />
        
        <div className="p-8">
          <header className="mb-8">
            <div className="flex items-center space-x-2 text-sm text-gray-500 mb-4">
              <span>{formatDate(post.date)}</span>
              <span>•</span>
              <span>{post.readTime} min de lecture</span>
              <span>•</span>
              <span className="text-blue-600">{post.category}</span>
            </div>
            
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              {post.title}
            </h1>
            
            <p className="text-xl text-gray-600">
              {post.excerpt}
            </p>
          </header>

          <div 
            className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />

          <footer className="mt-12 pt-8 border-t border-gray-200">
            <ShareButtons post={post} />
          </footer>
        </div>
      </article>

      {relatedPosts.length > 0 && (
        <div className="mt-12">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">Articles similaires</h2>
          <RelatedPosts posts={relatedPosts} />
        </div>
      )}
    </motion.div>
  );
}