import { motion } from 'framer-motion';

interface BurglaryStatsProps {
  burglaries: number;
}

export default function BurglaryStats({ burglaries }: BurglaryStatsProps) {
  return (
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      className="bg-gradient-to-br from-blue-50 to-blue-100 p-4 rounded-lg"
    >
      <h3 className="text-sm font-medium text-gray-500">Cambriolages par an</h3>
      <p className="mt-1 text-2xl font-semibold text-gray-900">
        {burglaries}
      </p>
    </motion.div>
  );
}