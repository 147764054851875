import { motion } from 'framer-motion';

export default function PrivacyPolicy() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="max-w-4xl mx-auto px-4 py-12"
    >
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Politique de Confidentialité</h1>

      <div className="space-y-8">
        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">1. Collecte des données</h2>
          <p className="text-gray-600">
            Dans le cadre de l'utilisation du service de diagnostic de sécurité PROTECTMYHOME, nous 
            collectons les informations suivantes :
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600 space-y-1">
            <li>Type de logement</li>
            <li>Adresse du logement</li>
            <li>Équipements de sécurité existants</li>
            <li>Code postal et ville</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">2. Utilisation des données</h2>
          <p className="text-gray-600">
            Les données collectées sont utilisées exclusivement pour :
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600 space-y-1">
            <li>Réaliser le diagnostic de sécurité personnalisé</li>
            <li>Générer des recommandations adaptées</li>
            <li>Analyser les statistiques locales de sécurité</li>
            <li>Améliorer nos services</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">3. Conservation des données</h2>
          <p className="text-gray-600">
            Les données personnelles sont conservées pendant une durée maximale de 12 mois après 
            la dernière utilisation du service. Au-delà de cette période, les données sont 
            automatiquement supprimées ou anonymisées.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">4. Partage des données</h2>
          <p className="text-gray-600">
            Nous ne partageons jamais vos données personnelles avec des tiers à des fins 
            commerciales. Les données peuvent être partagées uniquement avec nos sous-traitants 
            techniques dans le cadre strict de la fourniture du service.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">5. Cookies</h2>
          <p className="text-gray-600">
            Notre site utilise des cookies techniques essentiels au fonctionnement du service. 
            Ces cookies ne collectent pas de données personnelles et sont automatiquement 
            supprimés à la fermeture du navigateur.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">6. Vos droits</h2>
          <p className="text-gray-600">
            Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez 
            des droits suivants concernant vos données personnelles :
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600 space-y-1">
            <li>Droit d'accès</li>
            <li>Droit de rectification</li>
            <li>Droit à l'effacement</li>
            <li>Droit à la limitation du traitement</li>
            <li>Droit à la portabilité</li>
            <li>Droit d'opposition</li>
          </ul>
          <p className="mt-4 text-gray-600">
            Pour exercer ces droits, contactez-nous à : privacy@protectmyhome.fr
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">7. Sécurité</h2>
          <p className="text-gray-600">
            Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos données 
            contre tout accès, modification, divulgation ou destruction non autorisés.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">8. Modifications</h2>
          <p className="text-gray-600">
            Nous nous réservons le droit de modifier cette politique de confidentialité à tout 
            moment. Les modifications entrent en vigueur dès leur publication sur le site.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold text-gray-900 mb-4">9. Contact</h2>
          <p className="text-gray-600">
            Pour toute question concernant cette politique de confidentialité ou le traitement 
            de vos données, contactez notre Délégué à la Protection des Données :<br />
            Email : dpo@protectmyhome.fr<br />
            Adresse : [Adresse du DPO]
          </p>
        </section>
      </div>
    </motion.div>
  );
}