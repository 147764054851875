import { ShieldCheckIcon, ChartBarIcon, DocumentTextIcon } from '@heroicons/react/24/outline';

const features = [
  {
    icon: ShieldCheckIcon,
    title: "Diagnostic complet",
    description: "Analyse détaillée des points vulnérables de votre logement basée sur des critères professionnels."
  },
  {
    icon: ChartBarIcon,
    title: "Recommandations personnalisées",
    description: "Solutions adaptées à votre situation et conseils d'experts pour améliorer votre sécurité."
  },
  {
    icon: DocumentTextIcon,
    title: "Rapport détaillé",
    description: "Rapport PDF complet avec visualisation des zones à risque et solutions recommandées."
  }
];

export default function Features() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            Diagnostic Sécurité du Logement
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Une approche complète de votre sécurité
          </p>
        </div>

        <div className="mt-10">
          <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-col items-center p-6 bg-gray-50 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                <feature.icon className="h-12 w-12 text-blue-600" />
                <h3 className="mt-4 text-lg font-medium text-gray-900">{feature.title}</h3>
                <p className="mt-2 text-base text-gray-500 text-center">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}