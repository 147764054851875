interface StatisticsErrorProps {
  message: string;
}

export default function StatisticsError({ message }: StatisticsErrorProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="text-center text-gray-600">
        <p>{message}</p>
      </div>
    </div>
  );
}