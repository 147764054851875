import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { ref, get, update, set } from 'firebase/database';
import { db } from '../lib/firebase';
import { motion } from 'framer-motion';
import { ArrowRightIcon, ArrowLeftOnRectangleIcon, StarIcon as StarIconSolid, PencilIcon } from '@heroicons/react/24/solid';
import { StarIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';
import { DiagnosticFormData } from '../types/diagnostic';

interface UserProfile {
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
}

interface SavedDiagnostic {
  id: string;
  date: string;
  score: number;
  formData: DiagnosticFormData;
  isFavorite?: boolean;
}

export default function Dashboard() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [diagnostics, setDiagnostics] = useState<SavedDiagnostic[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    const loadUserData = async () => {
      if (!currentUser) {
        navigate('/login');
        return;
      }

      try {
        setError(null);
        console.log('Loading user data for:', currentUser.uid);
        
        // Récupération des données utilisateur
        const userRef = ref(db, `users/${currentUser.uid}`);
        const userSnapshot = await get(userRef);

        if (!userSnapshot.exists()) {
          console.log('No user profile found');
          setError('Profil utilisateur non trouvé. Veuillez vous déconnecter et vous reconnecter.');
          return;
        }

        const userData = userSnapshot.val();
        console.log('User data loaded:', userData);
        setProfile(userData);
        setEditedProfile(userData);

        // Récupération des diagnostics
        const diagnosticsRef = ref(db, `diagnostics/${currentUser.uid}`);
        const diagnosticsSnapshot = await get(diagnosticsRef);

        if (diagnosticsSnapshot.exists()) {
          const diagnosticsData = diagnosticsSnapshot.val();
          console.log('Diagnostics loaded:', diagnosticsData);
          
          const formattedDiagnostics = Object.entries(diagnosticsData)
            .map(([id, data]) => ({
              id,
              ...(data as Omit<SavedDiagnostic, 'id'>)
            }))
            .sort((a, b) => {
              // Trier d'abord par favoris, puis par date
              if (a.isFavorite && !b.isFavorite) return -1;
              if (!a.isFavorite && b.isFavorite) return 1;
              return new Date(b.date).getTime() - new Date(a.date).getTime();
            });

          setDiagnostics(formattedDiagnostics);
        } else {
          console.log('No diagnostics found');
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
        setError('Erreur lors du chargement des données. Veuillez réessayer.');
        toast.error('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    loadUserData();
  }, [currentUser, navigate]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      toast.error('Erreur lors de la déconnexion');
    }
  };

  const handleViewDiagnostic = (diagnostic: SavedDiagnostic) => {
    navigate('/results', { 
      state: { 
        formData: diagnostic.formData,
        fromDashboard: true
      } 
    });
  };

  const handleToggleFavorite = async (diagnostic: SavedDiagnostic) => {
    try {
      const diagnosticRef = ref(db, `diagnostics/${currentUser?.uid}/${diagnostic.id}`);
      await update(diagnosticRef, {
        isFavorite: !diagnostic.isFavorite
      });

      setDiagnostics(prev => prev.map(d => {
        if (d.id === diagnostic.id) {
          return { ...d, isFavorite: !d.isFavorite };
        }
        return d;
      }));

      toast.success(diagnostic.isFavorite ? 'Retiré des favoris' : 'Ajouté aux favoris');
    } catch (error) {
      toast.error('Erreur lors de la mise à jour des favoris');
    }
  };

  const handleSaveProfile = async () => {
    if (!currentUser || !editedProfile) return;

    try {
      const userRef = ref(db, `users/${currentUser.uid}`);
      await set(userRef, {
        ...editedProfile,
        updatedAt: new Date().toISOString()
      });

      setProfile(editedProfile);
      setIsEditing(false);
      toast.success('Profil mis à jour avec succès');
    } catch (error) {
      toast.error('Erreur lors de la mise à jour du profil');
    }
  };

  if (!currentUser) {
    return null;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="animate-pulse space-y-4">
            <div className="h-8 bg-gray-200 rounded w-1/4"></div>
            <div className="h-32 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <div className="text-center">
              <h2 className="text-xl font-semibold text-gray-900 mb-4">Erreur</h2>
              <p className="text-gray-600 mb-6">{error}</p>
              <button
                onClick={handleLogout}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700"
              >
                <ArrowLeftOnRectangleIcon className="h-5 w-5 mr-2" />
                Se déconnecter
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 sm:p-8">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-2xl font-bold text-gray-900">
                Tableau de bord
              </h1>
              <button
                onClick={handleLogout}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <ArrowLeftOnRectangleIcon className="h-5 w-5 mr-2" />
                Déconnexion
              </button>
            </div>

            {profile && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-50 rounded-lg p-6 mb-8"
              >
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold text-gray-900">
                    Informations du compte
                  </h2>
                  <button
                    onClick={() => setIsEditing(!isEditing)}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    <PencilIcon className="h-4 w-4 mr-2" />
                    {isEditing ? 'Annuler' : 'Modifier'}
                  </button>
                </div>

                {isEditing ? (
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Prénom
                        </label>
                        <input
                          type="text"
                          value={editedProfile?.firstName}
                          onChange={(e) => setEditedProfile(prev => prev ? {...prev, firstName: e.target.value} : null)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Nom
                        </label>
                        <input
                          type="text"
                          value={editedProfile?.lastName}
                          onChange={(e) => setEditedProfile(prev => prev ? {...prev, lastName: e.target.value} : null)}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        value={editedProfile?.email}
                        onChange={(e) => setEditedProfile(prev => prev ? {...prev, email: e.target.value} : null)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={handleSaveProfile}
                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
                      >
                        Enregistrer les modifications
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Nom complet</p>
                      <p className="font-medium text-gray-900">
                        {profile.firstName} {profile.lastName}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Email</p>
                      <p className="font-medium text-gray-900">{profile.email}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Membre depuis</p>
                      <p className="font-medium text-gray-900">
                        {new Date(profile.createdAt).toLocaleDateString('fr-FR')}
                      </p>
                    </div>
                  </div>
                )}
              </motion.div>
            )}

            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Mes diagnostics de sécurité
              </h2>

              {diagnostics.length > 0 ? (
                <div className="space-y-4">
                  {diagnostics.map((diagnostic) => (
                    <motion.div
                      key={diagnostic.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      className="bg-white border rounded-lg p-4 hover:shadow-md transition-shadow"
                    >
                      <div className="flex justify-between items-center">
                        <div>
                          <p className="font-medium text-gray-900">
                            {diagnostic.formData.address || diagnostic.formData.city}
                          </p>
                          <p className="text-sm text-gray-500">
                            {new Date(diagnostic.date).toLocaleDateString('fr-FR')}
                          </p>
                        </div>
                        <div className="flex items-center space-x-4">
                          <button
                            onClick={() => handleToggleFavorite(diagnostic)}
                            className="text-gray-400 hover:text-yellow-500"
                          >
                            {diagnostic.isFavorite ? (
                              <StarIconSolid className="h-5 w-5 text-yellow-500" />
                            ) : (
                              <StarIcon className="h-5 w-5" />
                            )}
                          </button>
                          <div className="text-right">
                            <p className="text-sm text-gray-500">Score</p>
                            <p className="font-medium text-gray-900">
                              {diagnostic.score}/100
                            </p>
                          </div>
                          <button
                            onClick={() => handleViewDiagnostic(diagnostic)}
                            className="inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark"
                          >
                            <ArrowRightIcon className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-12 bg-gray-50 rounded-lg">
                  <p className="text-gray-500">Aucun diagnostic enregistré</p>
                  <button
                    onClick={() => navigate('/diagnostic')}
                    className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primary-dark"
                  >
                    Faire mon premier diagnostic
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}