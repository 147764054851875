import { DiagnosticFormData } from '../../types/diagnostic';
import { getRecommendations } from '../../utils/securityCalculator';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRightIcon, ShieldExclamationIcon, ShieldCheckIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { Product, ProductCategories } from '../../types/product';

interface RecommendationsProps {
  formData: DiagnosticFormData;
}

const PRODUCTS: ProductCategories = {
  alarm: {
    name: "Systèmes d'alarme",
    products: [
      {
        id: 'alarm-1',
        name: 'Kit Alarme Pro Ajax',
        description: 'Système d\'alarme professionnel sans fil avec sirène',
        price: 399.99,
        image: '/images/products/alarm-system.jpg',
        url: 'https://amzn.to/3YMohwY',
        rating: 4.8,
        reviews: 1250,
        badges: ['choice'],
        keywords: ['alarme', 'système d\'alarme', 'kit alarme']
      }
    ]
  },
  sensor: {
    name: "Détecteurs et capteurs",
    products: [
      {
        id: 'sensor-1',
        name: 'Pack Détecteurs de Mouvement',
        description: 'Kit de 3 détecteurs sans fil, portée 12m',
        price: 89.99,
        image: '/images/products/motion-sensor.jpg',
        url: 'https://amzn.to/votre-lien-2',
        rating: 4.5,
        reviews: 950,
        badges: ['choice'],
        keywords: ['détecteur', 'mouvement', 'capteur']
      }
    ]
  },
  doorbell: {
    name: "Sonnettes connectées",
    products: []
  },
  signage: {
    name: "Signalétique",
    products: []
  },
  presence: {
    name: "Simulateurs de présence",
    products: []
  }
};

const PRODUCT_KEYWORDS: Record<string, keyof ProductCategories> = {
  'alarme': 'alarm',
  'détecteur': 'sensor',
  'détection': 'sensor',
  'mouvement': 'sensor',
  'ouverture': 'sensor',
  'sonnette': 'doorbell',
  'signalétique': 'signage',
  'occupation': 'presence',
  'présence': 'presence'
};

const priorityStyles = {
  high: {
    border: 'border-red-500',
    bg: 'bg-red-50',
    text: 'text-red-700',
    icon: 'text-red-500'
  },
  medium: {
    border: 'border-yellow-500',
    bg: 'bg-yellow-50',
    text: 'text-yellow-700',
    icon: 'text-yellow-500'
  },
  low: {
    border: 'border-green-500',
    bg: 'bg-green-50',
    text: 'text-green-700',
    icon: 'text-green-500'
  }
};

export default function Recommendations({ formData }: RecommendationsProps) {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const recommendations = getRecommendations(formData);

  const getProductsForRecommendation = (text: string): Product[] | null => {
    const lowercaseText = text.toLowerCase();
    for (const [keyword, category] of Object.entries(PRODUCT_KEYWORDS)) {
      if (lowercaseText.includes(keyword)) {
        return PRODUCTS[category]?.products || null;
      }
    }
    return null;
  };

  return (
    <div className="space-y-6">
      {recommendations.map((category, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className={`rounded-lg border-l-4 ${priorityStyles[category.priority].border} ${priorityStyles[category.priority].bg} overflow-hidden`}
        >
          <div className="p-4">
            <div className="flex items-center">
              {category.priority === 'high' ? (
                <ShieldExclamationIcon className={`h-6 w-6 ${priorityStyles[category.priority].icon} mr-3`} />
              ) : (
                <ShieldCheckIcon className={`h-6 w-6 ${priorityStyles[category.priority].icon} mr-3`} />
              )}
              <h3 className={`text-lg font-semibold ${priorityStyles[category.priority].text}`}>
                {category.title}
              </h3>
            </div>

            <div className="mt-4">
              <ul className="space-y-3">
                {category.items.map((item, itemIndex) => {
                  const products = getProductsForRecommendation(item);
                  const isSelected = selectedItem === `${index}-${itemIndex}`;

                  return (
                    <div key={itemIndex}>
                      <motion.li
                        className="flex items-start space-x-3 cursor-pointer"
                        onClick={() => setSelectedItem(isSelected ? null : `${index}-${itemIndex}`)}
                      >
                        <ArrowRightIcon className={`h-5 w-5 mt-0.5 flex-shrink-0 ${priorityStyles[category.priority].icon}`} />
                        <span className={`${priorityStyles[category.priority].text}`}>{item}</span>
                      </motion.li>

                      <AnimatePresence>
                        {isSelected && products && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            className="mt-4 ml-8 grid grid-cols-1 md:grid-cols-2 gap-4"
                          >
                            {products.map((product) => (
                              <a
                                key={product.id}
                                href={product.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="block bg-white rounded-lg border border-gray-200 shadow-sm hover:shadow-md transition-shadow"
                              >
                                <div className="p-4">
                                  <h4 className="text-lg font-medium text-gray-900 mb-2">
                                    {product.name}
                                  </h4>
                                  <p className="text-sm text-gray-600 mb-3">
                                    {product.description}
                                  </p>
                                  <div className="flex items-center justify-between">
                                    <span className="text-lg font-bold text-gray-900">
                                      {product.price.toFixed(2)}€
                                    </span>
                                    <div className="flex items-center space-x-2">
                                      <span className="text-sm text-gray-600">
                                        {product.rating}★ ({product.reviews})
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            ))}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}