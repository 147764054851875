import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getLocalStatistics, StatisticsServiceError } from '../../services/statisticsService';
import { DiagnosticFormData } from '../../types/diagnostic';
import { BurglaryStatistics } from '../../types/statistics';
import BurglaryStats from './statistics/BurglaryStats';
import StatisticsError from './statistics/StatisticsError';
import StatisticsLoading from './statistics/StatisticsLoading';
import StatisticsFooter from './statistics/StatisticsFooter';

interface LocalStatisticsProps {
  formData: DiagnosticFormData;
}

export default function LocalStatistics({ formData }: LocalStatisticsProps) {
  const [statistics, setStatistics] = useState<BurglaryStatistics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    const fetchStatistics = async () => {
      if (!formData.postalCode) return;

      try {
        setLoading(true);
        setError(null);

        const data = await getLocalStatistics(formData.postalCode);
        
        if (mounted) {
          setStatistics(data);
        }
      } catch (err) {
        if (!mounted) return;

        if (err instanceof StatisticsServiceError) {
          setError(err.message);
        } else {
          setError("Une erreur inattendue est survenue");
          console.error('Erreur inattendue:', err);
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchStatistics();

    return () => {
      mounted = false;
    };
  }, [formData.postalCode]);

  if (loading) {
    return <StatisticsLoading />;
  }

  if (error || !statistics) {
    return <StatisticsError message={error || "Données non disponibles"} />;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-lg shadow-lg p-6"
    >
      <h2 className="text-xl font-semibold text-gray-900 mb-6">
        Statistiques de sécurité - {formData.city}
      </h2>

      <div className="grid grid-cols-1 gap-4 mb-8">
        <BurglaryStats burglaries={statistics.burglaries} />
      </div>

      <StatisticsFooter 
        source={statistics.source}
        lastUpdate={statistics.lastUpdate}
        reliability={statistics.reliability}
      />
    </motion.div>
  );
}