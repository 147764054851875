import { motion } from 'framer-motion';
import { ShieldCheckIcon, LightBulbIcon, ClockIcon } from '@heroicons/react/24/outline';
import { getAssetPath } from '../../utils/assetPath';

export default function SecurityGuideSection() {
  return (
    <div className="bg-gray-50 py-16">
      {/* ... reste du code ... */}
      <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {/* ... reste du code ... */}
      </div>
      <div className="mt-12 text-center">
        <button
          onClick={() => window.open(getAssetPath('guide-securite.pdf'), '_blank')}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
        >
          Consulter le guide
        </button>
      </div>
    </div>
  );
}