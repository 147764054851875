import { useEffect } from 'react';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import { DiagnosticFormData } from '../../types/diagnostic';
import { calculateSecurityScore } from '../../utils/securityCalculator';

interface SecurityScoreProps {
  formData: DiagnosticFormData;
}

export default function SecurityScore({ formData }: SecurityScoreProps) {
  const { score, details } = calculateSecurityScore(formData);

  useEffect(() => {
    if (score >= 80) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 }
      });
    }
  }, [score]);

  const getBadge = (score: number) => {
    if (score >= 90) return { icon: '🏆', label: 'Expert en sécurité' };
    if (score >= 80) return { icon: '🥇', label: 'Maître de la protection' };
    if (score >= 70) return { icon: '🥈', label: 'Gardien averti' };
    if (score >= 60) return { icon: '🥉', label: 'Protecteur en progression' };
    return { icon: '🎯', label: 'Apprenti sécurité' };
  };

  const badge = getBadge(score);
  const radius = 85;
  const circumference = 2 * Math.PI * radius;
  const progress = (score / 100) * circumference;
  const remainingProgress = circumference - progress;

  const getScoreColor = (score: number) => {
    if (score < 35) return 'text-red-500';
    if (score < 65) return 'text-yellow-500';
    return 'text-green-500';
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="text-center mb-8">
        <div className="text-6xl mb-4">{badge.icon}</div>
        <h3 className="text-xl font-semibold text-gray-900">{badge.label}</h3>
      </div>

      <div className="flex justify-center mb-8">
        <div className="relative w-64 h-64">
          <svg className="w-full h-full transform -rotate-90" viewBox="0 0 200 200">
            <circle
              cx="100"
              cy="100"
              r={radius}
              fill="none"
              stroke="#E5E7EB"
              strokeWidth="12"
            />
            <motion.circle
              cx="100"
              cy="100"
              r={radius}
              fill="none"
              stroke="currentColor"
              strokeWidth="12"
              strokeLinecap="round"
              className={getScoreColor(score)}
              style={{
                strokeDasharray: `${progress} ${remainingProgress}`,
                transformOrigin: '100px 100px'
              }}
              initial={{ strokeDasharray: `0 ${circumference}` }}
              animate={{ strokeDasharray: `${progress} ${remainingProgress}` }}
              transition={{ duration: 1.5, ease: "easeOut" }}
            />
          </svg>
          
          <div className="absolute inset-0 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
              className="text-center"
            >
              <span className={`text-5xl font-bold ${getScoreColor(score)}`}>
                {score}
              </span>
              <span className="text-2xl text-gray-400 ml-1">/100</span>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        {details.map((detail, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.2 }}
            className="bg-gray-50 p-4 rounded-lg"
          >
            <div className="flex items-center justify-between mb-2">
              <span className="text-sm font-medium text-gray-600">{detail.label}</span>
              <span className={`text-sm font-bold ${getScoreColor(detail.score)}`}>
                {detail.score}/100
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <motion.div
                className={`rounded-full h-2 ${
                  detail.percentage < 35 ? 'bg-red-500' :
                  detail.percentage < 65 ? 'bg-yellow-500' :
                  'bg-green-500'
                }`}
                initial={{ width: 0 }}
                animate={{ width: `${detail.percentage}%` }}
                transition={{ duration: 1, delay: index * 0.2 }}
              />
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}