import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DiagnosticFormData } from '../types/diagnostic';
import SecurityScore from '../components/results/SecurityScore';
import SecurityMap from '../components/results/SecurityMap';
import LocalStatistics from '../components/results/LocalStatistics';
import Recommendations from '../components/results/Recommendations';
import SecurityGuide from '../components/results/SecurityGuide';
import { useAuth } from '../contexts/AuthContext';
import { ref, push } from 'firebase/database';
import { db } from '../lib/firebase';
import { calculateSecurityScore } from '../utils/securityCalculator';
import toast from 'react-hot-toast';
import { BookmarkIcon } from '@heroicons/react/24/outline';

export default function Results() {
  const location = useLocation();
  const navigate = useNavigate();
  const topRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useAuth();
  const formData = location.state?.formData as DiagnosticFormData;
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const isFromDashboard = location.state?.fromDashboard || false;

  useEffect(() => {
    if (!formData) {
      navigate('/diagnostic');
      return;
    }
    
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [formData, navigate]);

  const handleSaveDiagnostic = async () => {
    if (!currentUser) {
      toast.error('Veuillez vous connecter pour sauvegarder le diagnostic');
      navigate('/login');
      return;
    }

    try {
      setIsSaving(true);
      const { score } = calculateSecurityScore(formData);
      const diagnosticData = {
        formData,
        date: new Date().toISOString(),
        score,
        isFavorite: false
      };
      
      await push(ref(db, `diagnostics/${currentUser.uid}`), diagnosticData);
      setIsSaved(true);
      toast.success('Diagnostic sauvegardé avec succès');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du diagnostic:', error);
      toast.error('Erreur lors de la sauvegarde du diagnostic');
    } finally {
      setIsSaving(false);
    }
  };

  if (!formData) {
    return null;
  }

  return (
    <div ref={topRef} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8 flex justify-between items-center">
        <h1 className="text-3xl font-bold text-gray-900">Résultats de votre diagnostic sécurité</h1>
        {!isFromDashboard && currentUser && !isSaved && (
          <button
            onClick={handleSaveDiagnostic}
            disabled={isSaving || isSaved}
            className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium
              ${isSaved 
                ? 'bg-green-600 text-white cursor-default'
                : 'text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary'
              } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            <BookmarkIcon className="h-5 w-5 mr-2" />
            {isSaving ? 'Sauvegarde...' : isSaved ? 'Diagnostic sauvegardé' : 'Sauvegarder le diagnostic'}
          </button>
        )}
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <SecurityScore formData={formData} />
        <SecurityMap formData={formData} />
      </div>

      <div className="mb-8">
        <LocalStatistics formData={formData} />
      </div>

      <div className="space-y-8">
        <Recommendations formData={formData} />
        <SecurityGuide />
      </div>
    </div>
  );
}