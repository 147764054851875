import burglaryData from '../data/burglaryStats.json';
import { BurglaryStatistics, StatisticsError } from '../types/statistics';

export class StatisticsServiceError extends Error {
  constructor(
    public code: StatisticsError['code'],
    message: string
  ) {
    super(message);
    this.name = 'StatisticsServiceError';
  }
}

export async function getLocalStatistics(postalCode: string): Promise<BurglaryStatistics> {
  try {
    // Validation du code postal
    if (!postalCode || postalCode.length !== 5) {
      throw new StatisticsServiceError(
        'INVALID_POSTAL_CODE',
        'Code postal invalide'
      );
    }

    // Récupération des données
    const cityData = (burglaryData as Record<string, any>)[postalCode];
    if (!cityData?.d?.length) {
      throw new StatisticsServiceError(
        'NO_DATA',
        `Aucune donnée disponible pour le code postal ${postalCode}`
      );
    }

    // Récupération des données les plus récentes
    const latestData = cityData.d[cityData.d.length - 1];
    const burglaries = parseInt(latestData.f.toString());

    if (isNaN(burglaries)) {
      throw new StatisticsServiceError(
        'INVALID_DATA',
        'Données de cambriolages invalides'
      );
    }

    return {
      burglaries,
      source: "Données officielles du Ministère de l'Intérieur",
      lastUpdate: new Date().toISOString(),
      reliability: 95
    };
  } catch (error) {
    if (error instanceof StatisticsServiceError) {
      throw error;
    }
    
    console.error('Erreur inattendue lors de la récupération des statistiques:', error);
    throw new StatisticsServiceError(
      'UNKNOWN_ERROR',
      'Une erreur inattendue est survenue'
    );
  }
}