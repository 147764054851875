import { DiagnosticFormData } from '../../../types/diagnostic';
import { motion } from 'framer-motion';

interface HousingTypeProps {
  formData: DiagnosticFormData;
  updateFormData: (data: Partial<DiagnosticFormData>) => void;
}

export default function HousingType({ formData, updateFormData }: HousingTypeProps) {
  const isValid = formData.housingType && formData.floors >= 0;

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Type de logement</h2>
      
      <div className="space-y-8">
        <div>
          <label className="block text-sm font-medium text-gray-700">Type de bien</label>
          <div className="mt-2 grid grid-cols-2 gap-4">
            {['house', 'apartment'].map((type) => (
              <motion.button
                key={type}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  updateFormData({ 
                    housingType: type as 'house' | 'apartment',
                    hasAccessibleGarden: type === 'apartment' ? false : formData.hasAccessibleGarden
                  });
                }}
                className={`p-4 rounded-lg border-2 transition-colors ${
                  formData.housingType === type
                    ? 'border-primary bg-primary/10 text-primary'
                    : 'border-gray-200 hover:border-primary/50'
                }`}
              >
                {type === 'house' ? 'Maison' : 'Appartement'}
              </motion.button>
            ))}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Nombre d'étages
            <span className="text-xs text-gray-500 ml-1">(0 pour plain-pied)</span>
          </label>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              onClick={() => updateFormData({ floors: Math.max(0, formData.floors - 1) })}
              className="p-2 rounded-lg border border-gray-300 hover:bg-gray-50"
            >
              <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
              </svg>
            </button>
            <input
              type="number"
              min="0"
              max="50"
              value={formData.floors}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (!isNaN(value) && value >= 0) {
                  updateFormData({ floors: value });
                }
              }}
              className="block w-20 text-center rounded-lg border-gray-300 shadow-sm 
                       focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
            />
            <button
              type="button"
              onClick={() => updateFormData({ floors: Math.min(50, formData.floors + 1) })}
              className="p-2 rounded-lg border border-gray-300 hover:bg-gray-50"
            >
              <svg className="w-4 h-4 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
            </button>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Environnement</h3>
            
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Éclairage public
                </label>
                <select
                  value={formData.streetLighting || ''}
                  onChange={(e) => updateFormData({ streetLighting: e.target.value as 'good' | 'medium' | 'poor' })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                           focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                >
                  <option value="">Sélectionnez...</option>
                  <option value="good">Bien éclairé</option>
                  <option value="medium">Moyennement éclairé</option>
                  <option value="poor">Peu ou pas éclairé</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Visibilité depuis la rue
                </label>
                <select
                  value={formData.streetVisibility || ''}
                  onChange={(e) => updateFormData({ streetVisibility: e.target.value as 'high' | 'medium' | 'low' })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                           focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                >
                  <option value="">Sélectionnez...</option>
                  <option value="high">Très visible (proche de la rue)</option>
                  <option value="medium">Partiellement visible</option>
                  <option value="low">Peu visible (en retrait, végétation dense)</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Type de quartier
                </label>
                <select
                  value={formData.neighborhoodType || ''}
                  onChange={(e) => updateFormData({ neighborhoodType: e.target.value as 'residential' | 'mixed' | 'isolated' })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm 
                           focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                >
                  <option value="">Sélectionnez...</option>
                  <option value="residential">Résidentiel</option>
                  <option value="mixed">Mixte (résidentiel/commercial)</option>
                  <option value="isolated">Isolé</option>
                </select>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <motion.div 
              className="flex items-center"
              whileHover={{ scale: 1.01 }}
            >
              <input
                type="checkbox"
                id="hasBasement"
                checked={formData.hasBasement}
                onChange={(e) => updateFormData({ hasBasement: e.target.checked })}
                className="h-5 w-5 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="hasBasement" className="ml-2 block text-sm text-gray-700 cursor-pointer">
                Sous-sol ou cave
              </label>
            </motion.div>

            <motion.div 
              className="flex items-center"
              whileHover={{ scale: 1.01 }}
            >
              <input
                type="checkbox"
                id="hasGarage"
                checked={formData.hasGarage}
                onChange={(e) => updateFormData({ hasGarage: e.target.checked })}
                className="h-5 w-5 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="hasGarage" className="ml-2 block text-sm text-gray-700 cursor-pointer">
                Garage
              </label>
            </motion.div>

            {formData.housingType === 'house' && (
              <motion.div 
                className="flex items-center"
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  type="checkbox"
                  id="hasAccessibleGarden"
                  checked={formData.hasAccessibleGarden}
                  onChange={(e) => updateFormData({ hasAccessibleGarden: e.target.checked })}
                  className="h-5 w-5 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
                />
                <label htmlFor="hasAccessibleGarden" className="ml-2 block text-sm text-gray-700 cursor-pointer">
                  Jardin facilement accessible depuis la rue
                  <p className="text-xs text-gray-500 mt-1">
                    Le jardin est considéré comme facilement accessible s'il n'y a pas de clôture haute 
                    ou si l'accès n'est pas sécurisé (portail bas, haie basse, etc.)
                  </p>
                </label>
              </motion.div>
            )}

            <motion.div 
              className="flex items-center"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <input
                type="checkbox"
                id="hasAccessibleBalcony"
                checked={formData.hasAccessibleBalcony}
                onChange={(e) => updateFormData({ hasAccessibleBalcony: e.target.checked })}
                className="h-5 w-5 text-primary focus:ring-primary border-gray-300 rounded cursor-pointer"
              />
              <label htmlFor="hasAccessibleBalcony" className="ml-2 block text-sm text-gray-700 cursor-pointer">
                Balcon facilement accessible depuis l'extérieur
                <p className="text-xs text-gray-500 mt-1">
                  Le balcon est considéré comme facilement accessible s'il est situé au rez-de-chaussée 
                  ou accessible facilement depuis le sol (échelle, arbre, mur d'escalade, etc.)
                </p>
              </label>
            </motion.div>
          </div>
        </div>

        {!isValid && (
          <p className="text-red-500 text-sm mt-2">
            Veuillez sélectionner un type de logement et indiquer le nombre d'étages
          </p>
        )}
      </div>
    </div>
  );
}