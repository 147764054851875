import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BlogPost } from '../../types/blog';
import { formatDate } from '../../utils/dateFormatter';
import { getAssetPath } from '../../utils/assetPath';

interface RelatedPostsProps {
  posts: BlogPost[];
}

export default function RelatedPosts({ posts }: RelatedPostsProps) {
  if (posts.length === 0) return null;

  return (
    <div className="mt-16">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Articles similaires</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {posts.map((post, index) => (
          <motion.article
            key={post.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <Link to={`/blog/${post.slug}`}>
              <img
                src={getAssetPath(post.image)}
                alt={post.title}
                className="w-full h-40 object-cover"
              />
            </Link>
            {/* Reste du composant inchangé */}
          </motion.article>
        ))}
      </div>
    </div>
  );
}