import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StepIndicator from '../components/diagnostic/StepIndicator';
import HousingType from '../components/diagnostic/steps/HousingType';
import Location from '../components/diagnostic/steps/Location';
import SecurityEquipment from '../components/diagnostic/steps/SecurityEquipment';
import Navigation from '../components/diagnostic/Navigation';
import { DiagnosticFormData } from '../types/diagnostic';
import StepTransition from '../components/diagnostic/steps/StepTransition';

export default function DiagnosticForm() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState<DiagnosticFormData>({
    housingType: 'apartment',
    floors: 0,
    hasBasement: false,
    hasGarage: false,
    hasAccessibleGarden: false,
    hasAccessibleBalcony: false,
    streetLighting: '',
    streetVisibility: '',
    neighborhoodType: '',
    address: '',
    postalCode: '',
    city: '',
    equipment: []
  });

  const updateFormData = (data: Partial<DiagnosticFormData>) => {
    setFormData(prev => ({ ...prev, ...data }));
  };

  const isStepValid = () => {
    switch (currentStep) {
      case 1:
        return Boolean(formData.housingType);
      case 2:
        return formData.postalCode.length === 5 && formData.city !== '';
      case 3:
        return true;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(prev => prev + 1);
    } else {
      navigate('/results', { state: { formData } });
    }
  };

  const handlePrevious = () => {
    setCurrentStep(prev => prev - 1);
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <StepIndicator currentStep={currentStep} />
      
      <div className="mt-8 bg-white shadow-lg rounded-lg p-6">
        <StepTransition>
          {currentStep === 1 && (
            <HousingType 
              formData={formData} 
              updateFormData={updateFormData} 
            />
          )}
          
          {currentStep === 2 && (
            <Location 
              formData={formData} 
              updateFormData={updateFormData} 
            />
          )}
          
          {currentStep === 3 && (
            <SecurityEquipment 
              formData={formData} 
              updateFormData={updateFormData} 
            />
          )}
        </StepTransition>

        <Navigation 
          currentStep={currentStep}
          onPrevious={handlePrevious}
          onNext={handleNext}
          isValid={isStepValid()}
        />
      </div>
    </div>
  );
}