/**
 * Utilitaire pour gérer les chemins d'assets
 * @param path Chemin relatif de l'asset
 * @returns Chemin complet avec le bon préfixe
 */
export function getAssetPath(path: string): string {
  // Supprime le ./ initial si présent
  const cleanPath = path.startsWith('./') ? path.slice(2) : path;
  // Utilise un chemin relatif
  return `./${cleanPath}`;
}